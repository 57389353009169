const initState = {};

const userReducer = (state = initState, action) => {
  if (action.type === "") {
    return state;
  }
  return state;
};

export default userReducer;
