export const cities = [
  { key: 1, label: "New York", value: "New York" },
  { key: 2, label: "Toronto", value: "Toronto" },
  { key: 3, label: "Vancouver", value: "Vancouver" },
];
export const moveIn = [
  { key: 1, label: "August", value: "August" },
  { key: 2, label: "September", value: "September" },
  { key: 3, label: "October", value: "October" },
];
export const type = [
  { key: 1, label: "House", value: "House" },
  { key: 2, label: "Apartment", value: "Apartment" },
];
export const price = [
  { key: 1, label: "$0 - $1000", value: [0, 1000] },
  { key: 2, label: "$1000 - $2000", value: [1000, 2000] },
  { key: 3, label: "$2000 -- $3000", value: [2000, 3000] },
];

// export const _data = [
//   {
//     id: 1,
//     name: "Hopkins Ave",
//     city: "New York",
//     price: 1500,
//     moveIn: "September",
//     address: "13 Hopkins Ave, Manhattan, NY",
//     bed: 2,
//     bath: 2,
//     type: "House",
//     img: a,
//   },
//   {
//     id: 2,
//     name: "Baldwin Ave",
//     city: "New York",
//     price: 1200,
//     moveIn: "September",
//     address: "11 Baldwin Ave, Queens, NY",
//     bed: 2,
//     bath: 1,
//     type: "House",
//     img: b,
//   },
//   {
//     id: 3,
//     name: "JFK Avenue",
//     city: "New York",
//     price: 1000,
//     moveIn: "October",
//     address: "30 JFK Avenue, Brooklyn, NY",
//     bed: 2,
//     bath: 1,
//     type: "Apartment",
//     img: c,
//   },
//   {
//     id: 4,
//     name: "SouthHampton",
//     city: "New York",
//     price: 2000,
//     moveIn: "September",
//     address: "25 River Drive, Manhattan, NY",
//     bed: 3,
//     bath: 2,
//     type: "Apartment",
//     img: d,
//   },
//   {
//     id: 5,
//     name: "Riverside",
//     city: "New York",
//     price: 3200,
//     moveIn: "August",
//     address: "30 River Drive, Manhattan, NY",
//     bed: 2,
//     bath: 2,
//     type: "Apartment",
//     img: e,
//   },
//   {
//     id: 6,
//     name: "Palisade Ave",
//     city: "Boston",
//     price: 1500,
//     moveIn: "October",
//     address: "10 Palisade Ave, Boston, MA",
//     bed: 2,
//     bath: 1,
//     type: "House",
//     img: f,
//   },
//   {
//     id: 7,
//     name: "Pine Street",
//     city: "Boston",
//     price: 1200,
//     moveIn: "September",
//     address: "88 Pine Street, Boston, MA",
//     bed: 2,
//     bath: 1,
//     type: "House",
//     img: g,
//   },
//   {
//     id: 8,
//     name: "Maple Street",
//     city: "Boston",
//     price: 2000,
//     moveIn: "September",
//     address: "90 Maple Street, Boston, MA",
//     bed: 3,
//     bath: 2,
//     type: "House",
//     img: h,
//   },
//   {
//     id: 9,
//     name: "Cedar Street",
//     city: "Boston",
//     price: 1500,
//     moveIn: "October",
//     address: "5 Cedar Street, Boston, MA",
//     bed: 2,
//     bath: 1,
//     type: "Apartment",
//     img: i,
//   },
//   {
//     id: 10,
//     name: "Oak Street",
//     city: "Boston",
//     price: 2500,
//     moveIn: "August",
//     address: "2 Oak Street, Boston, MA",
//     bed: 3,
//     bath: 2,
//     type: "Apartment",
//     img: j,
//   },
//   {
//     id: 11,
//     name: "Elm Street",
//     city: "Philadelphia",
//     price: 1200,
//     moveIn: "August",
//     address: "13 Elm Street, Philadelphia, PA",
//     bed: 2,
//     bath: 1,
//     type: "House",
//     img: k,
//   },
//   {
//     id: 12,
//     name: "Lakeview",
//     city: "Philadelphia",
//     price: 1500,
//     moveIn: "August",
//     address: "10 Lakeview, Philadelphia, PA",
//     bed: 2,
//     bath: 1,
//     type: "House",
//     img: l,
//   },
//   {
//     id: 13,
//     name: "Hill Ave",
//     city: "Philadelphia",
//     price: 2000,
//     moveIn: "September",
//     address: "90 Hill Ave, Philadelphia, PA",
//     bed: 3,
//     bath: 2,
//     type: "Apartment",
//     img: m,
//   },
//   {
//     id: 14,
//     name: "Ninth Street",
//     city: "Philadelphia",
//     price: 2700,
//     moveIn: "September",
//     address: "14 Ninth Street, Philadelphia, PA",
//     bed: 3,
//     bath: 2,
//     type: "Apartment",
//     img: n,
//   },
//   {
//     id: 15,
//     name: "Washington Ave",
//     city: "Philadelphia",
//     price: 2200,
//     moveIn: "October",
//     address: "45 Washington Ave, Philadelphia, PA",
//     bed: 3,
//     bath: 2,
//     type: "Apartment",
//     img: o,
//   },
// ];
